import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AngularFirestore } from '@angular/fire/firestore';

import { MealDescription } from 'src/app/shared/services/mealDescription';
import { AuthService } from "../../shared/services/auth.service";
import { Observable } from 'rxjs';
import { MealType } from 'src/app/shared/enum/mealType';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  meal: any;
  mealDescription: MealDescription = {} as MealDescription;

  constructor(
    public authService: AuthService, private router: Router, private store: AngularFireDatabase
  ) { }

  SaveLunch() {
    this.store.object<MealDescription>('meals').set(this.mealDescription).then(_ => console.log('succes')).catch(error => console.log(error));
    // return this.store.collection('Lunch').add(this.mealDescription);
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Montserrat',
    fonts: [
      { class: 'montserrat', name: 'Montserrat' },
    ],
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit() {
    // console.log("meals");
    // var tet = this.store.collection<MealDescription>('Lunch');
    // console.log(tet);
    // var meals = this.store.collection<MealDescription>('Lunch').snapshotChanges().subscribe(data => {
    //   this.meal = data.map(e => {
    //     return {
    //       id: e.payload.doc.id,
    //       ...e.payload.doc.data()
    //     } as MealDescription
    //   });
    //   console.log(meals);
    // });
    // console.log(this.meal);
  }
}
