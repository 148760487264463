import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MealType } from '../shared/enum/mealType';
import { MealDescription } from '../shared/services/mealDescription';

@Component({
  selector: 'app-lunch',
  templateUrl: './lunch.component.html',
  styleUrls: ['./lunch.component.less']
})
export class LunchComponent implements OnInit {
  
  private itemsCollection: AngularFirestoreCollection<MealDescription>;
  mealDescription: Observable<MealDescription[]>;

  constructor(private store: AngularFirestore) {
    this.itemsCollection = store.collection<MealDescription>('meals');
   }

  ngOnInit() {
    console.log("meals");
    var tet = this.store.doc<MealDescription>('/meals/0');
    console.log(tet);

this.itemsCollection.get();

    this.mealDescription = this.store.collection<MealDescription>('meals', ref => ref.where('type', '==', MealType.Lunch).limit(1)).valueChanges();
    // this.mealDescription.subscribe(res => res.map(tet => tet.))
    // console.log(mealDescription);
    // var tet = mealDescription;
  }
}

