import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.less']
})
export class CarouselComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var slideIndex = 1;
    this.showSlides(slideIndex);
  }

  // Open the Modal
  openModal() {
    document.getElementById("myModal").style.display = "block";
  }

  // Close the Modal
  closeModal() {
    document.getElementById("myModal").style.display = "none";
  }

  slideIndex = 1;

  // Next/previous controls
  plusSlides(n: any) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n: any) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n: any) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    var captionText = document.getElementById("caption");
    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      (<HTMLElement>slides[i]).style.display = "none";
    }
    (<HTMLElement>slides[this.slideIndex - 1]).style.display = "block";
    dots[this.slideIndex - 1].className += " active";
  }
}
