import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.less']
})
export class GalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var slideIndex = 1;
    this.showSlides(slideIndex);
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == "ArrowLeft") {
      this.plusSlides(-1)
    }
    if (event.key == "ArrowRight") {
      this.plusSlides(1)
    }
    if (event.key == "Escape") {
      this.closeModal()
    }
  }

  // Open the Modal
  openModal() {
    document.getElementById("myModal").style.display = "block";
  }

  // Close the Modal
  closeModal() {
    document.getElementById("myModal").style.display = "none";
  }

  slideIndex = 1;

  // Next/previous controls
  plusSlides(n: any) {
    this.showSlides(this.slideIndex += n);
  }

  // Thumbnail image controls
  currentSlide(n: any) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n: any) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      (<HTMLElement>slides[i]).style.display = "none";
    }
    (<HTMLElement>slides[this.slideIndex - 1]).style.display = "block";
  }
}
