import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circles',
  templateUrl: './circles.component.html',
  styleUrls: ['./circles.component.less']
})
export class CirclesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
