import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lunch-editor',
  templateUrl: './lunch-editor.component.html',
  styleUrls: ['./lunch-editor.component.less']
})
export class LunchEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
